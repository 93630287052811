import styled from "styled-components";

export const Main = styled.main`
  padding: 4rem 0 0 0;
  /* background-color: cyan; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0 0;
  padding: 0 8px
`